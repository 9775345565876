import React from 'react';
import moment from 'moment';

export default {
  formatFromTarget: target => {
    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = Number(target.value);
        break;
      case 'react-datetime':
        value = moment(target.value).toISOString();
        break;
      default:
        value = target.value;
    }
    return value;
  },

  formatDateForAPI: date => {
    return moment(date).toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
  },

  formatDate: date => {
    return moment(date).format('DD/MM/YYYY');
  },

  formatDateTime: date => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  },

  formatNumberDecimals: (number, decimals = 5) => {
    const decimalPlaces = 10 ** decimals;
    number = Math.round(number * decimalPlaces) / decimalPlaces;
    let parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  },

  roomsOptions: data => {
   
    let infoRooms;
    if (data == 'null a null' || data == 'NULL') {
      return infoRooms = '*';
    } 
    if (data == '1 a 1') {
   
      return infoRooms = '1';
    } 
    if (data == '2 a 2') {
      return infoRooms = '2';
    } 
    if (data == '3 a 3') {
   
      return infoRooms = '3';
    }
    if (data == '4 a 4') {
      return infoRooms = '4';
    } 
    if (data == '5 a 5') {
      return infoRooms = '5';
    }
    
      infoRooms = data?.replace(/\samb\.$/i, '');
 
    return infoRooms;
  },


  replaceWord:data=>{
    let newDeliveryText;
    if (data?.toLowerCase().includes("entrega")) {
     
       newDeliveryText= data.replace(/entrega/gi, "POSESIÓN");
       newDeliveryText=newDeliveryText.toUpperCase();
      return newDeliveryText ;
  }
},

formatUSD:data=>{
  let usdFormat=data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return usdFormat;

},

formatARS:data => {
  // Asegurar que el número tenga dos decimales
  let number = Number(data).toFixed(2);
  
  // Reemplazar el punto por coma para los decimales
  let parts = number.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  
  return parts.join(',');
},
capitalizeFirstLetter :(str) => {
  if (!str) return str; 
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
},



formattAntiquity:data=>{
  {
    switch (data) {
      case -1:
        return 'En construcción';
      default:
        return `${data} años`;
    }
  };
},
}

