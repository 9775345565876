

'use client'
import React from 'react';
import apiHelper from '../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import {Select } from "@chakra-ui/react";

const SelectLocation = props => {
  const [options, setOptions] = useState([]);
  const [currentValue, setValue] = useState(null);
  const [input, setInput] = useState('');

  useEffect(() => {
    let { value } = props;
    if (value) {
      loadDefaultOptions(value);
    }
    if (!value) {
      setValue(null);
    }
  }, [props.value]);



  const handleChange = selectedValue => {
    setValue(selectedValue);
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'locationId';
    event.target.value = selectedValue
      ? Array.from(selectedValue, selectedValue => selectedValue.value)
      : null;
    event.target.type = 'react-select';
    props.onChange(event);
  };

  const defaultOptions = async () => {
    let filters = {
      where: {},
    };

    apiHelper
      .getLocationZones(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.id,
            label:
              entity.name + (entity.parentName ? ', ' + entity.parentName : ''),
          };
        });
        setOptions(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadOptions = (input, callback) => {
    if (!input.length>3) return;

    let filters = {
      where: {
        name: input,
      },
    };

    apiHelper
      .getLocationZones(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.id,
            label:
              entity.name + (entity.parentName ? ', ' + entity.parentName : ''),
          };
        });

        setOptions(entitiesForSelect);
        callback(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadDefaultOptions = value => {
    if (!value.length) return;

    let filters = {
      where: {
        id: value,
      },
    };

    apiHelper
      .getLocationZones(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity.id,
            label:
              entity.name + (entity.parentName ? ', ' + entity.parentName : ''),
          };
        });

        setValue(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <AsyncSelect
      className={props.className}
      defaultOptions={options}
      onChange={handleChange}
      loadOptions={loadOptions}
      value={currentValue}
      name={props.name}
      required={props.required}
      isMulti
      isClearable
      isDisabled={props.isDisabled}
      placeholder={props.placeholder || 'Ubicación'}
      noOptionsMessage={() => 'No disponible'}
    />
  );
};

export default SelectLocation;
