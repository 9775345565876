import React, { useEffect, useState } from 'react';
import AsyncSelect from "react-select/async";
import { components} from "react-select";

const SelectRooms = (props) => {
  const options = [
    { label: '1 ambiente', value: '1' },
    { label: '2 ambiente', value: '2' },
    { label: '3 ambiente', value: '3' },
    { label: '4 ambiente', value: '4' },
    { label: '5 ambiente o más', value: '5+' },
   
  ];

  const [currentValue, setValue] = useState('')

  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value && !currentValue) {
      userValue = options.filter(option => value.includes(option.value));
      if (userValue && value) setValue(userValue);
    }
    if(!value){
      setValue(null)
    }
  }, [props.value]);

  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <input className='mr-1' id={props.label} type="checkbox" checked={props.isSelected} />
      <label htmlFor={props.label}>{props.label}</label>
    </components.Option>
  );

  const onChange = (selected) => {
    setValue(selected);
    let arrayOfSelected ={ target:{
      name:'rooms' ,
      value: Array.from(selected, select=> select.value),
      type: 'value'
    }     
    }
    props.onChange(arrayOfSelected);
  };

  return (
    <AsyncSelect
      onChange={onChange}
      isMulti
      value={currentValue}
      closeMenuOnSelect={false}
      defaultOptions={options}
      hideSelectedOptions={false}
      loadOptions={options}
      isSearchable={false}
      placeholder={'Ambientes'}
      components={{ Option: CheckboxOption }}
    />
  );
};

export default SelectRooms;