module.exports.backend = {
    apiUrl: "https://dev.interwin-scrapper.venturing.com.ar/Interwin-Scrapper/api",
};

module.exports.captcha = "6LemO8woAAAAAJuS_8fXFTM1NUqOyQDRwzMgYkbw";

export const timeToOpenModal = 30000;

export const noPropertiesModal = 4000;

module.exports.invisibleCaptcha =  "6LdVSbkpAAAAAKpkgt1HGI_ompNC7V_FvsCPCRih";









