'use client';


import React,{useEffect, useState} from 'react'





const ChatBot = () => {


  useEffect(() => {
  
    // Agregar el script del chatbot al documento cuando el componente se monta
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://go.botmaker.com/rest/webchat/p/8ZGNV8K0FD/init.js';
    document.body.appendChild(script);
    
  
    return () => {
      // Limpiar el script del chatbot cuando el componente se desmonta

    };
  }, []);


  return null

    
  
};

export default ChatBot