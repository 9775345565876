const axios = require('axios');
const config = require('../config/config');
const qs = require('qs');

const baseURL = process.env.NEXT_PUBLIC_API_URL,
  isServer = typeof window === 'undefined';

/* APIs ENDPOINTS */
const SESSION_STORAGE_SESSION = 'session';
const SERVER_URL = config.backend.apiUrl;
const LOGIN_URL = SERVER_URL + '/auth/login';
const CHANGE_PASSWORD_URL = SERVER_URL + '/auth/change-password';
const RESET_URL = SERVER_URL + '/auth/reset';
const GENERATE_PASSWORD_URL = SERVER_URL + '/auth/generate-password';
const USERS_URL = SERVER_URL + '/user';
const USER_ROLES_URL = SERVER_URL + '/userRole';
const FILE_URL = SERVER_URL + '/file';
const ALL_FILES_URL = SERVER_URL + '/file/filesUrl';
const FILE_INDEX_URL = SERVER_URL + '/file/{id}';
const FILE_URL_URL = SERVER_URL + '/file/file/{id}';
const PROPERTY_URL = SERVER_URL + '/property';
const LOCATION_URL = SERVER_URL + '/location';
const CONTACT_URL = SERVER_URL + '/contact';

const HISTORY_URL = SERVER_URL + '/searchHistory';

function getBackendUrl() {
  return SERVER_URL;
}

// Auth APIs

function login(payload) {
  if (!sessionStorage) return;
  if (typeof window == 'undefined') return;

  return axios.post(LOGIN_URL, payload).then(function (response) {
    const token = response.data;

    sessionStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify({ token }));
    getCurrentUser().then(function (userResponse) {
      let userData = userResponse.data;
      userData.token = token;
      sessionStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify(userData));
      return userData;
    });
  });
}

function resetPassword(payload) {
  return axios
    .post(RESET_URL, {
      username: payload.email,
    })
    .then(function (response) {
      return response;
    });
}

function contactMail(payload) {
  return axios.post(CONTACT_URL, payload).then(function (response) {
    return response;
  });
}

function changePassword(payload) {
  return axios
    .post(
      CHANGE_PASSWORD_URL,
      {
        username: payload.username,
        password: payload.password,
        newPassword: payload.newPassword,
      },
      buildHeaders(),
    )
    .then(function (response) {
      return response;
    });
}

function generatePassword(payload) {
  return axios
    .post(GENERATE_PASSWORD_URL, payload, buildHeaders())
    .then(function (response) {
      return response;
    });
}

function logout() {
  if (!sessionStorage) return;
  sessionStorage.clear(SESSION_STORAGE_SESSION);
}

function isAuthenticated() {
  return typeof window !== 'undefined'
    ? sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null
    : false;
}

function isAdmin() {
  return typeof window !== 'undefined'
    ? sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
        JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).userRole &&
        JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).userRole
          .userRole == 'ADMIN'
    : false;
}

function buildHeaders() {
  /*  if (!sessionStorage) return;
  if (
    typeof window !== 'undefined' &&
    sessionStorage.getItem(SESSION_STORAGE_SESSION)
  ) {*/
  return {
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      /*   Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token,*/
    },
    /*}
    };
  } else {
    return null;
  }*/
  };
}

// User APIs

function getUsers(parameters = []) {
  return axios.get(
    `${USERS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getUsersCount(parameters = []) {
  return axios.get(
    `${USERS_URL + '/count'}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getUser(id) {
  return axios.get(USERS_URL + '/' + id, buildHeaders());
}

function postUser(body) {
  return axios.post(USERS_URL + '/', body, buildHeaders());
}

function patchUser(id, body) {
  return axios.patch(USERS_URL + '/' + id, body, buildHeaders());
}

function deleteUser(id) {
  return axios.delete(USERS_URL + '/' + id, buildHeaders());
}

function getCurrentUser() {
  return axios.get(USERS_URL + '/me', buildHeaders());
}

function getUserRoles(parameters = []) {
  return axios.get(
    `${USER_ROLES_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

// File APIs

function getFiles(parameters = []) {
  return axios.get(
    `${FILE_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getFilesCount() {
  return axios.get(FILE_URL + '/count', buildHeaders());
}

function getFile(id) {
  return axios.get(FILE_INDEX_URL.replace('{id}', id), buildHeaders());
}

function getFileURL(id) {
  return FILE_URL_URL.replace('{id}', id); //axios.get(FILE_URL_URL.replace('{id}', id), buildHeaders());
}

function getAllFileURLs() {
  return axios.get(ALL_FILES_URL, buildHeaders());
}

function postFile(file) {
  const data = new FormData();
  data.append('file', document.file);
  return axios.post(FILE_URL, data, buildHeaders());
}

function patchFile(id, payload) {
  return axios.patch(
    FILE_INDEX_URL.replace('{id}', id),
    payload,
    buildHeaders(),
  );
}

function deleteFile(id) {
  return axios.delete(FILE_INDEX_URL.replace('{id}', id), buildHeaders());
}

//Property APIs

function getProperties(parameters = []) {
  return axios.get(
    `${PROPERTY_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    //buildHeaders(),
  );
}

function getInterwinProperties(parameters = []) {
  return axios.get(
    `${PROPERTY_URL + '/interwin/'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getInterwinInternationalProperties(parameters = []) {
  return axios.get(
    `${PROPERTY_URL + '/interwin/international'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getProperty(id) {
  return axios.get(PROPERTY_URL + '/interwin/' + id);
}

function getInterwinPropertiesCount(parameters = []) {
  return axios.get(
    `${PROPERTY_URL + '/interwincount'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}
function getInterwinInternationalPropertiesCount(parameters = []) {
  return axios.get(
    `${PROPERTY_URL + '/interwincount/international'}?${qs.stringify(
      parameters,
      { skipNulls: true },
    )}`,
    buildHeaders(),
  );
}

//location APIs

function getLocationZones(parameters = []) {
  return axios.get(
    `${LOCATION_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getLocationZonesCount(parameters = []) {
  return axios.get(
    `${LOCATION_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}
function getInternationalLocationZones(parameters = []) {
  return axios.get(
    `${LOCATION_URL + '/international'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getInternationalLocationZonesCount(parameters = []) {
  return axios.get(
    `${LOCATION_URL + '/count/international'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getLocation(id) {
  return axios.get(
    LOCATION_URL + id, // buildHeaders()
  );
}

//Search history APIs

function getSearchHistories(parameters = []) {
  return axios.get(
    `${HISTORY_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getSearchHistoryCount(parameters = []) {
  return axios.get(
    `${HISTORY_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getSearchHistory(id) {
  return axios.get(HISTORY_URL + '/' + id, buildHeaders());
}

function postSearchHistory(body) {
  return axios.post(HISTORY_URL + '/', body, buildHeaders());
}
function postSearchClientHistory(body) {
  return axios.post(HISTORY_URL + '/client', body);
}

function patchSearchHistory(id, body) {
  return axios.patch(HISTORY_URL + '/' + id, body, buildHeaders());
}

function deleteSearchHistory(id) {
  return axios.delete(HISTORY_URL + '/' + id, buildHeaders());
}

function getPropertyWithoutAuth(id) {
  return axios.get(PROPERTY_URL + '/form/' + id, buildHeaders());
}

function postForm(body) {
  return axios.post(HISTORY_URL + '/contactForm', body, buildHeaders());
}

export default {
  SERVER_URL,
  getBackendUrl,
  logout,
  login,
  resetPassword,
  changePassword,
  generatePassword,
  isAuthenticated,
  isAdmin,
  getUsers,
  getUsersCount,
  getUser,
  postUser,
  patchUser,
  deleteUser,
  getCurrentUser,
  getUserRoles,
  getFiles,
  getFilesCount,
  getFile,
  getFileURL,
  getAllFileURLs,
  postFile,
  patchFile,
  deleteFile,
  getProperties,
  getProperty,
  getInterwinProperties,
  getInterwinPropertiesCount,
  getLocationZonesCount,
  getLocationZones,
  getInterwinInternationalProperties,
  getInterwinInternationalPropertiesCount,
  getInternationalLocationZonesCount,
  getInternationalLocationZones,
  getLocation,
  patchSearchHistory,
  postSearchHistory,
  postSearchClientHistory,
  getSearchHistory,
  getSearchHistoryCount,
  getSearchHistories,
  deleteSearchHistory,
  contactMail,
  getPropertyWithoutAuth,
  postForm,
};
